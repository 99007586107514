/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import { DTO } from "./DTOInterface";
import { Exercise, ProgramDay, TMemberProgramsDTO, TProgramMember } from "./ProgramsType";
import { CompaniesListDTOTransformer } from "./CompaniesListDTO";

export interface TSurveyAnswerDTO {
  today_answered_pulse: boolean;
  last_month_monthly: boolean;
  pulse_graph: PulseGraph;
  surveys: SurveyDTO[];
}

export interface PulseGraph {
  labels: string[];
  datasets: Dataset[];
}

export interface Dataset {
  label: string;
  data: Coordinate[];
  fill: boolean;
  backgroundColor?: string;
  borderColor: string;
  pointRadius: string;
  pointBackgroundColor: string;
}

export interface Coordinate {
  x: string;
  y: number;
}

export interface SurveyDTO {
  completion_timestamp: number;
  member_id: number;
  depression: number;
  current_section: number;
  anxiety: number;
  survey_id: string;
  answer: Answer[];
  id: number;
  first_device_used: any;
  creation_timestamp: number;
  survey: Survey2;
  last_device_used: string;
}
export interface Answer {
  order: number;
  section: Section;
  question: Question[];
}

export interface Section {
  title: string;
  subtitle: string;
}

export interface Question {
  question: Question2;
  question_id: string;
  childs?: Child[];
}

export interface Question2 {
  id: string;
  flag?: string;
  type: string;
  noFit: any;
  title: string;
  answer: any;
  format: any;
  choices: string[];
  required?: boolean;
  scaleMax: any;
  scaleMin: any;
  noteLabel?: string;
  noteOrder?: number;
  answerType: any;
  noFitAnswer: number[];
  textChoices: string[];
  ScaleMinLabel?: string;
  scaleMaxLabel: any;
  triggerChoices: any[];
}

export interface Child {
  child: Child2;
  child_id: string;
  parent_id: string;
}

export interface Child2 {
  id: string;
  flag: string;
  type: string;
  noFit: any;
  title: string;
  answer: string;
  format: string;
  choices: string[];
  required: any;
  scaleMax: any;
  scaleMin: any;
  noteLabel: any;
  noteOrder: any;
  answerType: any;
  noFitAnswer: any[];
  textChoices: any[];
  ScaleMinLabel: any;
  scaleMaxLabel: any;
  triggerChoices: any[];
}

export interface Survey2 {
  part: number;
  id: string;
  survey_type: string;
  sections: number;
  title: string;
  lang: string;
  pain_area_id: string;
  title_es: string;
}

export interface TTestimonialDTO {
  id: number;
  company_id: string;
  testimonial: string;
}

export interface ShortPADTO {
  id: number;
  pain_area: string;
}

export interface ShortPA {
  id: number;
  painAreaName: string;
}

export interface TProfileDTO {
  pain_areas: ShortPADTO[];
  first_login: boolean;
  testimonial_submitted: null | boolean;
  exercise_reminder: null; //ExerciseReminder;
  main_pain_area_id: number;
  residence_state: string;
  video_quality: any;
  demo_member: boolean;
  relationship: string;
  company_id: any;
  member_risks: any[];
  user: User;
  presurgery_program: any;
  automated_sms: any;
  automated_emails: any;
  accidental_signup: boolean;
  recommendations: any;
  creation_timestamp: any;
  utm_object: null; //UtmObject;
  registration_method: string;
  company: any;
  meet_link: any;
  user_id: string;
  external_id: any;
  verified_user: boolean;
  id: number;
  apero_id: any;
  notifications_paused: any;
  terms_accepted: any;
  how_met_us: any;
  light_sensitive: any;
}

// export interface ExerciseReminder {}

export interface User {
  last_name: string;
  coach: any;
  gender: any;
  phone_number: string;
  email: string;
  id: string;
  first_name: string;
  language: string;
  last_logged_in: number;
  birth_date: string;
  tmp_email: string | null;
  personal_email: string | null;
}

// export interface UtmObject {}

export interface PainArea {
  pain_area_id: string;
  red_flags_count: number;
  not_a_fit: any;
  last_response: number;
  start_date: any;
  intake_completed: boolean;
  status: Status;
  unpause_date: any;
  intro_call_event_id: any;
  no_show_intro_call: any;
  stats: Stats;
  current_program_id: any;
  application_submitted: number;
  current_program: any;
  accepted_in_limbo_date: any;
  paused_reason: any;
  no_fit_questions: any[];
  intake_answer_id: number;
  coach: any;
  last_program_position: any;
  intro_call_date: any;
  time_zone: any;
  current_week: number;
  intake_answer: IntakeAnswer;
  intake_part_responded: any;
  coach_id: any;
  intro_call_completed: any;
  favorites: any;
  id: number;
  red_flag: any;
  potentially_not_a_fit: any;
  intro_call_channel: any;
  member_id: number;
  applicant_notes: any;
  notes: string;
  status_id: number;
  testimonial: TTestimonialDTO;
  testimonial_submitted: boolean | null;
  pre_surgery_program: null | boolean;
}

export interface Status {
  modified_date: number;
  status: string;
  id: number;
  member_painarea_id: number;
}

export interface Stats {
  lastDate: number;
  lastPain: number;
  firstDate: number;
  firstPain: number;
  currentDate: number;
  currentPain: number;
  firstAnxiety: number;
  firstDepression: number;
  currentSatisfaction: any;
}

export interface IntakeAnswer {
  current_section: number;
  id: number;
}

export class ProfileDTOTransformer implements DTO {
  DTO: TProfileDTO;
  SADTO: TSurveyAnswerDTO;
  MPDTO: TMemberProgramsDTO;
  PADTO: PainArea;
  constructor(MemberListDTO: TProfileDTO, surveyAnswerDTO: TSurveyAnswerDTO, memberProgramsDTO: TMemberProgramsDTO, painAreaDTO: PainArea) {
    this.DTO = MemberListDTO;
    this.PADTO = painAreaDTO;
    this.SADTO = surveyAnswerDTO;
    this.MPDTO = memberProgramsDTO;
  }

  getOriginalDTO() {
    return this.DTO;
  }

  getModel() {
    const convertedItem = this.convertItem();
    return convertedItem;
  }

  static parseProgram(p: TProgramMember) {
    return {
      completionTimestamp: moment.unix(p.completion_timestamp).fromNow(),
      completionPercent: p.completion_percent,
      title: p.program?.title,
      slug: p.id,
      id: p.id,
      description: p.program?.description,
      currentDay: p.current_day,
      days: p.program_days?.sort((a, b) => a["position"] - b["position"]).map(ProfileDTOTransformer.parseDay),
      startTime: moment.unix(p.start_time).format("MM/DD/YYYY hh:mm A"),
      currentDayStartTime: moment.unix(p.program_days[p.current_day]?.start_time).format("MM/DD/YYYY hh:mm A"),
      position: p.position,
      active: p.active,
      maintenance: p.maintenance,
      currentDayIndex: p.current_day,
    };
  }

  static parseSurveys(s: SurveyDTO) {
    return {
      surveyId: s.id,
      date: s.creation_timestamp, //"08/04/2021",
      creationTimestamp: s.completion_timestamp, //1628104487,
      title: s.survey?.title,
      completionTimestampEpoc: s.completion_timestamp,
      completionTimestamp: moment.unix(s.completion_timestamp).format("MM/DD/YYYY"), // "08/04/2021"
      answer: {
        Title: s.survey?.title,
        SurveyType: s.survey.survey_type, //"intake",
        Sections: s.answer?.map(ProfileDTOTransformer.parseSection) || [],
        Slug: s.survey.id, //"intake-survey",
        PainArea: s.survey.pain_area_id,
      },
    };
  }

  static parseSection(a: Answer) {
    return {
      Subtitle: a.section?.subtitle,
      Title: a.section?.title,
      Questions: a.question?.map(ProfileDTOTransformer.parseQuestion),
    };
  }

  static parseQuestion(q: Question) {
    return q;
  }

  static parseDay(d: ProgramDay) {
    return {
      title: d?.title,
      started: d.started,
      completed: d.completed,
      exercises: d.exercises.map(ProfileDTOTransformer.parseExercise),
    };
  }

  static parseExercise(e: Exercise) {
    return {
      title: e.exercise?.title,
      slug: e.exercise_id,
      video: {
        url: e.exercise.video.Url,
        hlsUrl: e.exercise.video.HlsUrl,
        cmafUrl: e.exercise.video.CmafUrl,
        thumbnailUrl: e.exercise.video.ThumbnailUrl,
      },
    };
  }
  static parseShortPainArea({ id, pain_area }: ShortPADTO): ShortPA {
    return { id, painAreaName: pain_area };
  }

  convertItem() {
    const selectedPA: PainArea = this.PADTO;

    const generatePrograms = () => {
      const result: any[] = [];
      if (selectedPA.current_program) {
        result.push(ProfileDTOTransformer.parseProgram(selectedPA.current_program as TProgramMember));
      }
      if (this.MPDTO.current_programs && this.MPDTO.current_programs.length >= 1) {
        let copyCurrent = [...this.MPDTO.current_programs];
        copyCurrent = copyCurrent.sort((a, b) => a["position"] - b["position"]);
        //Remove first element
        copyCurrent.shift();
        result.push(...(copyCurrent ?? []).map(ProfileDTOTransformer.parseProgram));
      }
      return result;
    };

    return {
      id: this.DTO.user_id,
      user_id: this.DTO.id,
      painAreaId: this.PADTO.id,
      painAreaList: this.DTO.pain_areas.map(ProfileDTOTransformer.parseShortPainArea),
      firstName: this.DTO.user.first_name,
      lastName: this.DTO.user.last_name,
      startDate: moment.unix(selectedPA.start_date).format("MM/DD/YYYY"),
      mainPainAreaId: this.DTO?.main_pain_area_id,
      alias: this.DTO.company ? CompaniesListDTOTransformer.convertCompanies(this.DTO.company) : {},
      firstLogin: this.DTO.first_login,
      lastLogin: moment.unix(this.DTO.user.last_logged_in).fromNow(),
      lastResponse: moment.unix(selectedPA.last_response).fromNow(),
      phoneNumber: this.DTO.user.phone_number,
      email: this.DTO.user.email,
      personalEmail: this.DTO.user.personal_email,
      tmpEmail: this.DTO.user.tmp_email,
      coach: selectedPA.coach,
      currentPrograms: generatePrograms(),
      completedPrograms: this.MPDTO.completed_programs?.map(ProfileDTOTransformer.parseProgram),
      completedSurveys: this.SADTO.surveys?.map(ProfileDTOTransformer.parseSurveys),
      notes: selectedPA.notes,
      applicantNotes: selectedPA.applicant_notes,
      pulse_graph: this.SADTO.pulse_graph,
      monthlyAnswered: this.SADTO.last_month_monthly,
      pulseAnswered: this.SADTO.today_answered_pulse,
      status: selectedPA?.status?.status,
      PainArea: selectedPA.pain_area_id,
      introCallCompleted: selectedPA.intro_call_completed,
      memberRisks: this.DTO.member_risks,
      accidentalSignup: this.DTO.accidental_signup,
      preSurgeryProgram: selectedPA?.pre_surgery_program,
      pausedReason: selectedPA.paused_reason,
      unpauseDate: selectedPA.unpause_date,
      lastStatusDate: selectedPA.status.modified_date,
      stats: selectedPA.stats,
      lang: this.DTO.user.language || "en",
      registrationMethod: this.DTO.registration_method,
      noShowIntroCall: selectedPA.no_show_intro_call,
      introCallEventId: selectedPA.intro_call_event_id,
      introCallDate: selectedPA.intro_call_date,
      automatedSMS: this.DTO.automated_sms,
      automatedEmails: this.DTO.automated_emails,
      timeZone: selectedPA.time_zone,
      howMetUs: this.DTO.how_met_us,
      notificationsPaused: this.DTO.notifications_paused,
      TestimonialSubmitted: selectedPA.testimonial_submitted,
      intakePartResponded: selectedPA.intake_part_responded,
      potentiallyNotAFit: selectedPA.potentially_not_a_fit,
      exerciseReminder: this.DTO.exercise_reminder,
      introCallChannel: selectedPA.intro_call_channel,
      callLink: this.DTO.meet_link,
      videoQuality: this.DTO.video_quality,
      additionalTerms: this.DTO.terms_accepted,
      favorites: selectedPA.favorites,
      testimonial: selectedPA?.testimonial?.testimonial,
      inLimbo: false,
      demoMember: this.DTO.demo_member,
      redFlagsCount: selectedPA.red_flags_count,
      redFlag: selectedPA.red_flag,
    };
  }
}
