import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";

import "./index.css";
import { useLocation } from "react-router-dom";

function PreSurgeryRoute() {
  const { t } = useTranslation();
  const location = useLocation(); //To use location.state
  const routeState = location.state;
  const [painArea] = useState(routeState?.painArea || "");

  return (
    <Container fluid className="pre-surgery-container">
      <Row>
        <Col xl={{ span: 6, offset: 3 }} lg={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }}>
          <Row>
            <Col className="completed-survey-title-container">
              <span className="title">{t("pre_surgery_screen.header")}</span>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Trans i18nKey={"pre_surgery_screen.body_1"}>
                <span className="info">
                  We're excited to help you on your <span>{{ painArea }}</span> journey. Our team is reviewing your survey, and you'll
                  receive program access within 48 hours after your coach has personalized the initial exercises to your needs.
                </span>
              </Trans>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Trans i18nKey={"pre_surgery_screen.body_2"}>
                <span className="info">
                  If you have any additional notes, instructions, or exercises from your surgeon, please email them to{" "}
                  <a href="mailto:coach@healthjoy.com">coach@healthjoy.com</a>. These insights will help us create a more effective and
                  customized plan for you.
                </span>
              </Trans>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Trans i18nKey={"pre_surgery_screen.body_3"}>
                <span className="info" style={{ fontStyle: "italic" }}>
                  Haven't scheduled your intro call yet?{" "}
                  <a href="https://calendly.com/healthjoy-msk-coaches/healthjoy-introductory-call-surgery-program">Click Here</a> to set up
                  a time that works for you!
                </span>
              </Trans>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default PreSurgeryRoute;
